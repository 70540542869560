
.our-team{
    border-radius: 15px;
    text-align: center;
    padding: 20px 15px 30px;
    background: #fff;

}
.our-team .pic{
    background: #fff;
    padding: 10px;
    margin-bottom: 25px;
    display: inline-block;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease 0s;
}
.our-team:hover .pic{
    background: #6FC6E1;
    border-radius: 50%;
}
.pic img{
    width: 100%;
    height: auto;
    border-radius: 50%;
}
.our-team .title{
    font-weight: 600;
    color: #2e282a;
    text-transform: uppercase;
    display: block;
    font-size: 20px;
    margin: 0 0 7px 0;
}
.our-team .post{
    color: #17bebb;
    text-transform: uppercase;
    display: block;
    font-size: 15px;
    margin-bottom: 15px;
}

.our-team .social{
    padding: 0;
    margin: 0;
    list-style: none;
}
.our-team .social li{
    display: inline-block;
    margin-right: 5px;
}
.our-team .social li a{
    border-radius: 50%;
    font-size: 15px;
    color: #6FC6E1;
    border: 1px solid #17bebb;
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    transition: all 0.5s ease 0s;
}
.our-team:hover .social li a{
    background: #6FC6E1;
    color: #fff;
}

.quota {
    margin-top: 20px !important;
}

.quotaSentence {
    font-weight: bold;
}