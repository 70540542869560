.about{
    position: relative;
    margin-top: 1100px;
}

.contentAbout{
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.descriptionAbout {
    & p {
        font-size: 18px;
    }
}

@media only screen and (max-width: 1400px) {
    .descriptionAbout {
        & p {
            font-size: 16px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .descriptionAbout {
        & p {
            font-size: 14px;
        }
    }

}

.imageAbout img{
    width: 100%;
}

@media only screen and (max-width: 992px) {
    .imageAbout{
        display: none;
    }

    .about{
        margin-top: 1000px;
    }
}

@media only screen and (max-width: 768px) {
    .descriptionAbout{
        margin-top: 10px;
    }
}