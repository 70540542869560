.navbar{
    height: 120px;
}
.nav-link{
    width:120px;
    height: 60px;
    color : white !important;
    background-color: #6FC6E1;
    background-image: linear-gradient(to right, #6FC6E1 , #007984);
    border-color: #6FC6E1;
    padding: 8px !important;
    margin: 0 20px;
    border-radius: 0 0 12rem 12rem;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
}

.imageLogo{
    width: 300px;
}

.nav-link:hover{
    background-color: #007984;
}

.topNavlink{
    box-shadow: 0 -2px  0 rgba(185, 172, 172, 0.6);
    margin: 0 5px;
}

@media only screen and (max-width: 992px) {
    .nav-link{
        color : black !important;
        background-color: white;
        background-image: none;
        border: none
    }

    .topNavlink{
        background-color: white;
    }

    .nav-link:hover{
        background-color: white;
        border: none;
    }
}

@media only screen and (max-width: 768px) {
    .imageLogo{
        width: 200px;
    }

    .nav-link{
        height: 40px;
    }
}