.contactIcon{
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size:24px;
    width:80px;
    height:80px;
    line-height:40px;
    text-align:center;
    border-radius:50%;
    border:1px solid #ccc;
    margin:25px 8px;
    opacity:0.75;
    color: white !important;
    background-image: linear-gradient(#6FC6E1 , #007984);
}

.contaktCol{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    column-gap: 10px;
}

.titleContact{
    font-weight: bold;
}

.contactSection a {
    color: black;
    text-decoration: none;
}

.contactUs{
    margin: 0 auto;
}

.contactDesc{
    margin: 20px 0;
    text-align: center;
}