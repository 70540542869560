.section{
    margin: 150px 0;
}

.titleSection {
    width:fit-content;
    height: 60px;
    color : white !important;
    background-color: #6FC6E1;
    background-image: linear-gradient(#6FC6E1 , #007984);
    border-color: #6FC6E1;
    padding: 15px !important;
    border-radius: 1rem 1rem 0 0;
    display: flex;
    justify-content: center;
    box-shadow: 0 4px 2px -2px rgba(185, 172, 172, 0.6);
}

@media only screen and (max-width: 1400px) {
    .section{
        margin: 100px 0;
    }
    
}

@media only screen and (max-width: 1200px) {
    .section{
        margin: 80px 0;
    }
    
}

@media only screen and (max-width: 768px) {
    .titleSection {
        margin: 0 auto;
    }
}

@media only screen and (max-width: 576px) {
    .section{
        margin: 50px 0;
    }
}