.serviceCol{
    padding: 0;
}

.img-container {
    overflow: hidden;
    position: relative;

    & .centeredDesc {
        display: none;
    }

    & img {
     width: 100%;
     transition: transform 0.3s;
    }

    & img:hover {
        transform: scale(1.5);
    }
}

.img-container:hover{
    .centeredDesc{
    display: block;
    color: white;
    left: 0;
    position:absolute;
    text-align:center;
    top: 40%;
    width: 100%;
    padding: 30px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
    }

    & img {
        filter: blur(8px);
        -webkit-filter: blur(8px);
    }

    @media only screen and (max-width: 1400px) {
        .centeredDesc{
            font-size: 1rem;
        }
    }

    @media only screen and (max-width: 440px) {
        .centeredDesc{
            font-size: .7rem;
        }
    }
}

.col{
    padding: 0;
}

.centered {
    color: white;
    left: 0;
    position:absolute;
    text-align:center;
    top: 20%;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2rem;
}

@media only screen and (max-width: 1400px) {
    .centered{
        font-size: 1.5rem;
    }
}


  